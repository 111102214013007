import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

const Privacy = () => {
  return (
    <>
      <Nav
        title="PRIVACY POLICY | Andover MA to Boston Airport Transfer"
        desc="Andover Taxi Service is providing an affordable & reliable pick and drop taxi service from Andover MA to Logan airport transfer. Go through our Privacy Policy"
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap contact__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Privacy Policy
              </h2>
              <p>
                Dear Visitor, We would be glad if you can spare some time and go
                through our privacy policy and terms of use. Andover Airport
                Cars is very concerned about your privacy policy and assures you
                that we exceed the industry standard in safeguarding your
                personal details and information that you have ever shared with
                us.
              </p>

              <p>
                <strong>Tracking Traffic on our sites:</strong>
              </p>

              <p>
                To track our web traffic we use third party software form Google
                that is fairly open standard. So your geographic location and
                other site usage are tracked but the user remains anonymous all
                the time. We use this data for our internal reporting purposes
                and to ensure better Andover Airport Cars.
              </p>

              <p>
                <strong>Use of Cookies and IP logging:</strong>
              </p>

              <p>
                We assure you that they are all open standard methods and used
                by most websites and blogs today. So you are in safe hands. When
                you submit a request, we might also track your IP to validate
                the request and avoid spamming of our sites.
              </p>

              <p>
                <strong>Use of Personal Information:</strong>
              </p>

              <p>
                We sometimes ask you for personal information like email, Name,
                and websites while commenting on the blogs or sending a
                contact/query request. While email data is not public and your
                name and website address that you share is public in comments.
                Under no circumstances will these protected data be shared with
                a third entity. (Refer to spamming cases)
              </p>

              <p>
                <strong>Spamming our Site:</strong>
              </p>

              <p>
                When our automated spam detection system detects your comments
                or queries as spam we might report your IP and email address for
                blacklisting. This is a global practice to nail the spam demon.
              </p>

              <p>
                <strong>Content on Site:</strong>
              </p>

              <p>
                We make sure the content on our site is original. Whenever we
                use graphics by other creators we make sure to provide
                appropriate credentials. In case you find some of them lacking
                credit or if you find your work on our site and do not approve
                it then let us know we shall remove it within 48 hours.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Privacy;
