import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

import ListGroup from "react-bootstrap/ListGroup";

const About = () => {
  return (
    <>
      <Nav
        title="Andover to Logan airport taxi services – Andover Airport Taxi"
        desc="Reliable taxi services with affordable fares from Andover to Logan International airport in Massachusetts. Unbeatable prices and services we offer."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap ">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
            About Us
          </h2>
          <Row>
            <Col lg="12">
              <h4 className="mb-4  small__title ">
                About Logan Airport to Andover taxi, town car and limo services
              </h4>
              <p className="text-left">
                Andover airport taxi and car services is one of the best taxi,
                town car, SUV and Limousine Services Company in Andover ma. We
                have been providing airport transportation services from Logan
                airport to Andover from last twelve years. Andover Airport taxi
                and car services is always reliable for your ride from Logan
                airport to Andover and from Andover to Boston Logan
                international airport.
              </p>
              <p>
                There are a lot of taxi companies for airport transportation
                from Andover to Boston Logan International airport and from
                Boston Logan International airport to Andover. All of the taxi
                companies may not be good for you as you expect for your service
                regarding punctuality and regarding your time to attend your
                valuable meetings or greetings. All of our driver are well
                trained and enough punctual to meet your timely needed. They
                will never be late for your ride to your meeting at Andover ma
                from Boston Logan international airport or from Boston Logan
                International airport to Andover ma.
              </p>
              <p>
                There are a lot of Logan taxi Services Company but Andover
                Airport taxi and Car services always promise you to take your
                destination on time and we never make you late at your
                destination. You will get our driver as like as your chauffeur.
                They will be always helpful for your luggage, baggage and for
                your kids.
              </p>
              <p>
                Some time you will find our car services is as lower as taxi
                services and ride with Andover airport taxi and car services
                will be very much comfortable for you and your family or friends
                or colleagues
              </p>
              <p>
                We always appreciate your suggestion for the better services of
                Logan Airport Taxi that services from Boston Logan International
                airport to Andover ma and Andover ma to Boston Logan
                International airport.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-5">
              <h4 className="mb-4   small__title ">Features</h4>
              <ListGroup className="  diamond-list">
                <ListGroup.Item>
                  Andover to Logan airport taxi cab, town car, SUV and Limousine
                  service
                </ListGroup.Item>
                <ListGroup.Item>
                  Logan airport to Andover taxi cab, town car, SUV and Limousine
                  service
                </ListGroup.Item>
                <ListGroup.Item>
                  Child and Booster seat available for your baby and kids
                </ListGroup.Item>
                <ListGroup.Item>
                  Better rate compared to any other company and services.
                </ListGroup.Item>
                <ListGroup.Item>
                  We bet price for with other for the same type of services.
                </ListGroup.Item>
                <ListGroup.Item>
                  All of your booked car will be arrived to you on time.
                </ListGroup.Item>
                <ListGroup.Item> 
                  Easy booking without any type of hassle
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default About;
