import React from "react";

import Nav from "../components/NavComp";
import Banner from "../components/MainBannerComp";
import TwoColWithImage from "../components/TwoColWithImage";
import ThreeColWithImage from "../components/ThreeColWithImage";
import ServiceList from "../components/ServiceList";
import TwoColAppSection from "../components/TwoColAppSection";
import ReviewSlider from "../components/ReviewSlider";
import FooterComp from "../components/FooterComp";

const Index = () => {
  return (
    <>
      <div className="App">
        <Nav
          title="Andover ma to Logan Airport taxi cab, town car, SUV and Limousine services"
          desc="Andover airport Taxi Company provides the best taxi cab and town car services from Logan International airport to Andover, MA."
        />
        <Banner />
        <TwoColWithImage />
        <ThreeColWithImage SectionTitle="Our Fleet" />
        <ServiceList SectionTitle="Our Service" />
        <TwoColAppSection />
        <ReviewSlider SectionTitle="What Our Clients are saying About us" />
        <FooterComp />
      </div>
    </>
  );
};

export default Index;
