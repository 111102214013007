import React, { Component } from "react";
import Quote from "../components/QuoteComp";
import Button from "react-bootstrap/Button";
import Banner from "../images/banner.png";

const MainBannerComp = () => {
  return (
    <div className="hp__banner">
      <img src={Banner} alt="banner" />
      <Quote />
      <div className="banner__text">
        <h1>
          Most <span>Trusted Taxi Services</span> in ANDOVER
        </h1>
        <Button className="btn__main">Discover More</Button>
      </div>
    </div>
  );
};

export default MainBannerComp;
