import react from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftIconMediaPlain from "./LefticonMediaPlain";

import app from "../images/appscreen.png";
import search from "../images/search-icon.png";
import clock from "../images/clock-outline-icon.png";
import price from "../images/cashless-payment-icon.png";

const TwoColAppSection = ({ Info = null}) => {
  const defaultInfo = [
    {
      icon: search,
      title: "Easy Searching",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.",
    },
    {
      icon: clock,
      title: "Quick Pickups",
      description:
        "Labore et dolore magna aliqua. Ut enim corehenderit in cupidatat non proident, sun.",
    },
    {
      icon: price,
      title:"Inclusive Rates",
      description: "Serspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    },
  ];

  if (!Info) Info = defaultInfo;

  return (
    <>
      <div className="section__gap mob__app__wrapper  pt-0">
        <Container fluid>
          <Row>
            <Col  lg="6" className="text-center">
              <img src={app} alt="image " />
            </Col>
            <Col  lg="6">
                <h4 className="section__title mt-5">
                Get a Mobile Application
                </h4>
              <Row>
                {Info.map((info, i) => (
                  <Col lg="12" key={i}>
                    <LeftIconMediaPlain
                      icon={info.icon}
                      title={info.title}
                      description={info.description}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TwoColAppSection;
