import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FooterComp from "../components/FooterComp";
import Button from "react-bootstrap/Button";
import ReCAPTCHA from "react-google-recaptcha";

const Fleet = () => {
  return (
    <>
      <Nav
        title="Andover airport taxi - a reliable taxi cab service company in Andover"
        desc="A reliable taxi cab company in Andover, MA that you can always trust for your ride."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap contact__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Contact Us
              </h2>
            </Col>
            <Col lg="7">
              <h4 className="mb-4  small__title ">
                Contact us for Andover to/from Boston Logan Airport
                transportation
              </h4>
              <p>
                Andover airport taxi intends to serve better transportation
                services from Andover ma to Boston Logan International airport
                and from Boston Logan International airport to Andover. Please
                contact us for your reservation or any other question about
                taxi, town car, car, minivan, van, SUV and limousine services
                from Boston Logan International airport to Andover ma or from
                Andover ma to Boston Logan International airport.
              </p>
              <h3 className=" small__title">
                <strong>Andover Airport Car & Taxi Service</strong>
              </h3>
              <p>Andover, MA-01845</p>
              <p>
                <a href="tel:9782962776">(978) 296 2776</a>
              </p>
              <p>
                <a href="mailto:info@airporttaxiandover.com">
                  info@airporttaxiandover.com
                </a>
              </p>
            </Col>
        
            <Col lg="5">
              <Form className="contact-form">
                <h3>Send your Queries</h3>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Full Name" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Phone Number" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Email Address" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control as="textarea" rows="4" placeholder="Message" />
                </Form.Group>
                <ReCAPTCHA sitekey="111" />,
                <Button className="btn-block btn__main btn__gradient">Submit</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Fleet;
