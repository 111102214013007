import React from "react";

const InnerBanner = ({ BannerImage }) => {
  return (
    <>
      <section className="inr__banner">
        <img src={BannerImage} alt="" />
      </section>
    </>
  );
};
export default InnerBanner;
