import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftIconMedia from "./LeftIconMedia";

import clock from "../images/clock-icon.png"
import car from "../images/car-icon.png"
import baby from "../images/baby.png"
import price from "../images/best-price.png"
import suv from "../images/suv-icon.png"
import payment from "../images/payment-method-icom.png"
import booking from "../images/booking-icon.png"

const ServiceList = ({ Info = null, SectionTitle }) => {
  const defaultInfo = [
    {
      icon: clock,
      description:
        "All of your booked car will be arrived to you on time.",
    },
    {
      icon: car,
      description:
        "Andover to Logan airport taxi cab, town car, SUV and Limousine service",
    },
    {
      icon: baby,
      description:
        "Child and Booster seat available for your baby and kids ",
    },
    {
      icon: price,
      description:
        "We bet price for with other for the same type of services.",
    },
    {
      icon: suv,
      description:
        "Logan airport to Andover taxi cab, town car, SUV and Limousine service",
    },
    {
      icon: payment,
      description:
        "Better rate compared to any other company and services. ",
    },
    {
      icon: booking,
      description:
        "Easy booking without any type of hassle ",
    },
    
  ];

  if (!Info) Info = defaultInfo;
  return (
    <>
      <div className="section__gap service__list  pt-0">
        <Container fluid>
          <div className="text-center">
            <h2 className="section__title__with__underline">{SectionTitle}</h2>
          </div>
          <Row>
            {Info.map((info, i) => (
              <Col   lg="4" key={i}>
                <LeftIconMedia
                  icon={info.icon}
                  title={info.title}
                  description={info.description}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};
export default ServiceList;
