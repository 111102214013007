import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import aboutImg from "../images/about.png";

const TwoColWithImage = ({ Text = null }) => {
  const defaultText = [
    {
      title:
        "Andover to Logan Airport taxi cab, town car, SUV and Limousine services",
      description:
        "Welcome to Andover Airport Taxi and Car Services. When you need a ride from Andover to Boston Logan International airport, we can be your reliable taxi cab, town car, and Limousine and SUV service provider. We have a branch of cars, taxi, SUV and limo for your luxurious ride. Our booking system is very much easy and you can book our car service by calling us at: (978) 296 2776 or by online booking form. We will confirm your booking within sort time.",
      description2:
        "All of our drivers are very much professional and they are reliable. They have driving experience more than decade. They are very much punctual and they will always assist you with your luggage and other staff that you need during your ride.",
      description3:
        " We are providing ride from Boston Logan International airport to Andover for more than ten year. We always believe in your satisfaction and you have all the right to notify us if you think we can do better for you in any way. All of your suggestion will be highly appreciated.",
    },
  ];

  if (!Text) Text = defaultText;
  return (
    <>
      <section className="section__gap twocol__image">
        <Container fluid>
          <Row>
            <Col lg="5">
              <img className="img-fluid" src={aboutImg} alt="image" />
            </Col>
            <Col  lg="7">
              {Text.map((info, i) => (
                <div key={i}>
                  <h2 class="section__title purple__text">{info.title || ""}</h2>
                  <p className="description">{info.description || ""}</p>
                  <p className="description">{info.description2 || ""}</p>
                  <p className="description">{info.description3 || ""}</p>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default TwoColWithImage;
