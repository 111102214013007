import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import logo from "../images/logo.png";

const FooterComp = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer>
        <Container fluid>
          <Row>
            <Col lg="4">
              <img src={logo} />
              <p>
                Andover airport taxi and car services is one of the best taxi,
                town car, SUV and Limousine Services Company in Andover ma. We
                have been providing airport transportation services from Logan
                airport to Andover from last twelve years. 
              </p>
              <div className="social-icons">
                <a href="#!">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a href="#!">
                  <i class="fa-brands fa-twitter"></i>
                </a>
                <a href="#!">
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </div>
            </Col>
            <Col lg="2">
              <h4>Useful Links</h4>
              <ListGroup>
                <ListGroup.Item>
                  <a href="/">Home</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/about">About us</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/service-area">Service Area</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/fleet">Fleet</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg="2">
              <h4>Engage</h4>
              <ListGroup>
                <ListGroup.Item>
                  <a href="/contact">Contact us</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/terms-and-conditions">Terms & Conditions</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/privacy-policy">Privacy Policy</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg="4">
              <p>
                Get your work done from the top selection of the best Equipments
                in the world.
              </p>
              <Form>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </InputGroup.Text>
                  <Form.Control placeholder="What are you looking for?" />
                </InputGroup>
              </Form>
            </Col>
          </Row>
          <hr />
          <p className="copyright">
            Copyright {year} ANDOVER Limited. All rights reserved.
          </p>
        </Container>
      </footer>
    </>
  );
};
export default FooterComp;
