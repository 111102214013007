import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import review from "../images/reviweer.png";

const ReviewSlider = ({ Info = null, SectionTitle }) => {
  const settings = {
    dots: true,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          arrows: false
        }
      },
    ]
  };
  const defaultInfo = [
    {
      icon: review,
      title: "Hannah Schmitt",
      date: "May 8 2020",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus ",
    },
    {
      icon: review,
      title: "Mr. Man",
      date: "May 8 2022",
      description:
        "Labore et dolore magna aliqua. Ut enim corehenderit in cupidatat non proident, sun.",
    },
    {
      icon: review,
      title: "John Doe",
      date: "July 8 2022",
      description:
        "Serspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    },
  ];

  if (!Info) Info = defaultInfo;
  return (
    <>
      <div className="section__gap homepage-review  pt-0">
        <Container fluid>
          <div className="text-center">
            <h2 className="section__title__with__underline">{SectionTitle}</h2>
          </div>
          <Row>
            <Col xs lg="12">
            <Slider {...settings}>
                {Info.map((info, i) => (
                  <div className="reviewitem" key={i}>
                    <p>{info.description}</p>
                    <div className="reviewer-info">
                      <img src={info.icon} alt="" />
                      <p>
                        {info.title}
                        <small>{info.date}</small>
                      </p>
                    </div>
                  </div>
                ))}
             </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default ReviewSlider;
