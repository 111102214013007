import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import Banner from "../images/about-banner.png";

import FooterComp from "../components/FooterComp";
import ThreeColWithImage from "../components/ThreeColWithImage";
const Fleet = () => {
  return (
    <>
      <Nav
        title="Andover Airport Fleets Service | Andover Airport Car"
        desc="Andover Airport Taxi Services, Andover Airport Car Cambridge, Andover Airport Minivan Service Andover Airport Car Burlington, Andover Airport Car, Andover Airport Minivan Service, Andover Airport Transfer, Andover Airport Transfers, Andover Airport Shuttle, Andover Airport Car"
      />
      <InnerBanner BannerImage={Banner} />
      <div className="fleet-page ">
        <ThreeColWithImage SectionTitle="Our Fleet" />
      </div>
      <FooterComp />
    </>
  );
};
export default Fleet;
