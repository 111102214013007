import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Img1 from "../images/suv.png";
import Img2 from "../images/sedan.png";
import Img3 from "../images/compact.png";

const ThreeColWithImage = ({ Data = null, SectionTitle }) => {
  const defaultData = [
    {
      title: "COMPACT SEDAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img1,
      rating: "5.0",
      capacity: "1-2",
    },
    {
      title: "3-PAX SEDAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img2,
      rating: "5.0",
      capacity: "2-3",
    },
    {
      title: "6/7-PAX MINIVAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img3,
      rating: "4.5",
      capacity: "6-7",
    },
    {
      title: "6/7-PAX MINIVAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img3,
      rating: "4.5",
      price: "$670",
      capacity: "1-2",
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <div className="section__gap threecolwithimage__wrapper ">
      <Container fluid>
        <div className="text-center">
          <h2 className="section__title">{SectionTitle}</h2>
          <p className="w-60 mt-5 mb-5">
           </p>
        </div>
        <div className="text-center">
          <Row>
            {Data.map((info, i) => (
              <Col className="threecolwithimage__unit"  lg="3" key={i}>
                <figure>
                  <img src={info.img} alt={info.title || ""} />
                </figure>
                <div className="fleet__info">
                  <h4>{info.title || ""}</h4>
                  <p className="description">{info.description || ""}</p>
                  
                  <div className="mob__fleet__info">
                    <span>
                      <i className="fa fa-star"></i> ({info.rating})
                    </span>
                    <span>
                      <i class="fa-regular fa-user"></i> {info.capacity}
                    </span>
                  </div>
                   
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ThreeColWithImage;
