import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

const Terms = () => {
  return (
    <>
      <Nav 
       title="Terms & Conditions | Airport Transfer - Andover Airport Car"
       desc="We would be glad if you can spare some time and go through our Terms & Conditions. Andover Airport Car Service is very concerned about Terms & Conditions"
  />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap contact__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Terms & Conditions
              </h2>
              <p>
                Concord airport taxi is a taxi company proving service in all
                over Concord area and Concord to Boston Logan International
                airport. It is operating its business since 2014 with a very
                good reputation.
              </p>
              <p>
                <strong>
                  Terms & Conditions: Concord Airport Taxi & Car Services.
                </strong>
              </p>

              <p>
                We accept all types of Credit Cards &amp;{" "}
                <strong>
                  PayPal. Concord Airport Taxi &amp; Car Services (DBA)
                </strong>{" "}
                is incorporated by <strong> Logan Airport Car, Inc </strong>{" "}
              </p>
              <p>
                <strong>" LOGAN AIRPORT CAR, INC ”</strong>{" "}
                <strong>
                  will be appear on Credit Card / PayPal Billing Statement.
                </strong>{" "}
              </p>
              <p>
                <strong>Service Hours of Operation:</strong> We provide{" "}
                <strong>24/7</strong> Airport Transportation -Except State of
                Emergency.{" "}
              </p>
              <p>
                <strong>Payment Options:</strong>{" "}
                <strong>PayPal or Credit Card. </strong> An options of payment
                type must be required to complete online booking. All PayPal
                payment goes under the corporate email name{" "}
                <strong>(loganairportcarservice@gmail.com)</strong> .{" "}
              </p>
              <p>
                <strong>Luggage Restriction:</strong> There are maximum luggage
                restrictions depending on the vehicle Capacity. Please check
                your vehicle luggage capacity from the fleet/Fare Quote page.
                Any additional Luggage may cost each $3.00 – Please clarify the
                Luggage amount while booking.{" "}
              </p>
              <p>
                <strong>Change:</strong> Change/Modify/Cancellation is free of
                cost before <strong> 4 (Four) hours </strong> of actually
                scheduled pickup.{" "}
                <strong>
                  Please simply email to: info@concordairporttaxi.com for the
                  change or Cancel Request.
                </strong>{" "}
              </p>
              <p>
                <strong>Refund:</strong> Any refund which is Paid by{" "}
                <strong> PayPal/Credit Card</strong> will be processed within{" "}
                <strong> 24 (Twenty Four) hours </strong>{" "}
              </p>
              <p>
                <strong>No-Show:</strong> : In case of passenger no-show. A
                no-show <strong>(full fare) </strong> will occurred, no refund
                will be allowed. Passenger must contact us – or- email for
                reschedule/change/cancel an existing reservation{" "}
                <strong> 4 (Four) hours </strong> hours before scheduled pickup.{" "}
              </p>
              <p>
                <strong>Cancellation:</strong> Cancellation before{" "}
                <strong> 4 (Four) hours </strong> - will be refunded in full.
                Less than <strong> 4 (Four) hours </strong> a refund will not be
                allowed.{" "}
              </p>
              <p>
                <strong>Reservation:</strong>{" "}
                <strong> (DAY) From 8:00 A.M - 8:00 P.M.</strong> Online
                Reservation Required at least <strong> 4 (Four) hours </strong>{" "}
                advance to schedule a Car.
                <strong>(NIGHT) From 8:00 PM - 8:00 AM</strong> required minimum{" "}
                <strong> 8 (Eight) hours </strong> for online reservation.
                <strong>
                  Need Urgent Booking, Please Call us: (978) 371-2224.
                </strong>{" "}
              </p>
              <p>
                <strong>Tolls/Fees:</strong> All Tolls &amp; Charges are
                Included in fare as per Massachusetts Transportation Authority.{" "}
              </p>
              <p>
                <strong>State Emergency:</strong> In case of State Emergency or
                snow Storms or heavy snowfall, we might have to cancel the
                service, we will contact passenger before cancellation. Canceled
                for Natural Causes, Change/Refund can be requested/Issued.{" "}
              </p>
              <p>
                <strong>To Airport Pick-up: Waiting Time:</strong>{" "}
                <strong>All Pick-up to the Airport</strong> - Driver will wait
                up to <strong> 15 (Fifteen) minutes. </strong> Please contact
                driver, if you need more time. Please contact driver, if you
                need more time or No-Show may occurred.{" "}
              </p>
              <p>
                <strong>From Airport Pick-up: Waiting Time:</strong>{" "}
                <strong> All Pickup-from the airport</strong> - Driver will wait
                up to <strong> 1 (One) hour </strong> from the actual flight
                arrival/landing time. If you need more than 1 (ONE) hours -
                Please contact driver, if you need more time or No-Show may
                occurred.{" "}
              </p>
              <p>
                <strong>Waiting Charges:</strong> Waiting charge is $1.00 per
                minutes.{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Terms;
