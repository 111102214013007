import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";

import ListGroup from "react-bootstrap/ListGroup";
import FooterComp from "../components/FooterComp";

const ServiceArea = () => {
  return (
    <>
      <Nav
        title="Logan airport to Andover taxi service area and fares – Andover Airport Taxi"
        desc="We provide taxi, town car and limo and SUV services in all areas of Andover from Logan airport with affordable prices. Get your fares from Andover to Logan airport for taxi, Limo or SUV services."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Service Area
              </h2>
            </Col>
            <Col lg="12">
              <h4 className="mb-4  small__title ">
                Andover to Boston Logan International airport and nearby cities
              </h4>
              <p className="text-left">
                Airport taxi Andover has been providing reliable and comfortable
                taxi cab, sedan car, SUV, VAN and Limousine services for years
                from Boston Logan International Airport to Andover, ma and from
                Andover ma to Boston Logan International Airport,
                Manchester-Boston Regional Airport, Laurence G. Hanscom Field
                Airport and T. F. Green Airport.
              </p>
              <p>
                Airport taxi Andover is providing taxi cab services for your
                ride to Boston Logan International airport or any other airport
                in Massachusetts from all over Andover city and nearby cities
                with our modern taxi cab, sedan car, SUV, van and minivan. We
                provide taxi services for each part of Andover.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="section__gap bg-dim  service__area__list ">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h4 className="mb-4  ">
                Boston Logan International Airport Transportation services for
                the following areas
              </h4>
              <p className="text-center">
                We are providing transport services from Andover Airport to each
                part of the city including the following interesting points:
              </p>
              <p>
                <strong>
                  Frequently provided taxi cab, town car, suv, van and minivan
                  services from the following area to Boston Logan International
                  airport.
                </strong>
              </p>
              <ListGroup className="box-list-inline">
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Barrett's Farm
                </ListGroup.Item>

                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  River Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Fiske St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Christian Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Bailey Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Pheasant Run
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Susan Bruce Studios
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Avery Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Atwood Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Stoneybrook Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Ravens Bluff
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Boutwell Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Nollet Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Freemont Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Fraiser Road
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Nollet Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Winchester Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Peterof Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Greybirch Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Greenbriar Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Cobblestone Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Furnari Farm Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Cross St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Inwood Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Forest Hill Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Fossen Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Launching Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Apollo Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Mercury Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Gemini Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Brundrett Ave
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  1776 Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Minuteman Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Old River Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Laurel Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Chestnut Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  New England Bus Center Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Campanelli Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Riverside Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Bulfinch Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Webster St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Steven St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Off Webster St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Ashbury Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Fun Flight Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  North St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Brookside Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Pine Brook Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Spring Valley Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Federal St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Somerset Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Bedford Pl
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Suffolk Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Norwich Pl
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Joseph St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Matthew St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Boardwalk Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Northfield Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  St James Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Off Webster St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Greenwood Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Ledge Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Chandler Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Pettingill Ave
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Iron Gate Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Southridge Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Pauline Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Beacon St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Noel Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Cemetary Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Corbett St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Topping Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Juliette St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  McKenney Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Walker Ave
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Shepley St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Binney St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Shattuck Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Tech Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Belle Isle Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Donald Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Richard Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Cloverfield Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Dandelion Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Possum Hollow Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Aspen Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Bittersweet Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Deerberry Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Brierwood Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Mulberry Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Sandalwood Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Alpine Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Pepperidge Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Sugarbush Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Brady Loop
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Monahan Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Greenbriar Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Fraiser Road
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Freemont Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Boutwell Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Knollcrest Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Pleasant St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Kalia Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  High Plain Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Hacienda Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Haggetts Pond Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  High Plain Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Penni Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  High Meadow Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Golden Oaks Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Ridge Hill Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Wedgewood Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Black Birch Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Cherrywood Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Deerfield Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Pendant Ct
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Avella Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Gavin Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Hitchcock Farm Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Holmes Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Sevilla Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Lamancha Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Granada Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Ashford Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Old Schoolhouse Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Hay Bale Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Larchmont Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Doyle Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Starr Ave E
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Gina Jo Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Penbrook Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Langley Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Belle Haven Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Langley Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Berkeley Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Belle Haven Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Rutgers Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Sheffield Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Hampton Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Rindge Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Ithica Pl
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Lakeside Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Evergreen Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Jordyn Lane
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Brown St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Newport Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Newport Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Deca Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Kendall Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Brown St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Regis Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Barron Ct
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Granli Dr
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Stouffer Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Osgood St
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Patricia Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Harmony Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Marigold Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Rasmussen Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Frontage Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Research Park
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Keystone Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Hearthstone Pl
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Woodview Way
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Dean Cir
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Woodman Ridge Rd
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Starwood Crossing
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Murray Hill Ln
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i>
                  Greenwood Rd
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
      <FooterComp />
    </>
  );
};

export default ServiceArea;
